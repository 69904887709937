@mixin social-btn-variant($background) {
  color: $white;
  background-color: $background;
  border: 0;
  transition: all .2s;
  &:hover,
  &:focus,
  &:active {
    color: $white;
    text-decoration: none;
    background-color: darken($background, 8%);
  }
}
