@mixin icon-btn-variant($size) {
  height: $size;
  width: $size;
  line-height: $size;
  i { line-height: $size; }
  &.icon-btn-lined {
    line-height: ($size - 4px);
    i { line-height: ($size - 4px); }

    &.icon-btn-thin {
      line-height: ($size - 2px);
      i { line-height: ($size - 2px); }
    }
  }
}

