
.badge-status-dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  vertical-align: middle;
  top: -1px
}

sup.badge-status-dot {
  vertical-align: super;
}

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-status-variant($value);
  }
}
