#app-v2-layout {
  > .app-breadcrumb {
    padding: 1.5rem 3rem;
  }

  > .app-content {
    padding: 0 3rem 3rem;

    > .ant-layout {
      border-radius: $border-radius;
      overflow: hidden;
    }
  }

  #app-content {
    background-color: $white;
  }
}