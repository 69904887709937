.bg-gradient-v1 { background: linear-gradient(120deg, #a8edea 0%, #fed6e3 100%); }
.bg-gradient-v2 { background: linear-gradient(120deg,#7f54e6,#3F51B5); }
.bg-gradient-v3 { background: linear-gradient(120deg,#152bc3,#5366d8); }
.bg-gradient-v4 { background: linear-gradient(120deg,#ecef3a,#ec6b9b); }
.bg-gradient-v5 { background: linear-gradient(120deg,#baedff,#07a2da); }
.bg-gradient-v6 { background: linear-gradient(120deg,#9C27B0,#090772); }
.bg-gradient-v7 { background: linear-gradient(-20deg, #f794a4 0%, #fdd6bd 100%); }
.bg-gradient-v8 { background: linear-gradient(120deg, #84fab0, #8fd3f4); }
.bg-gradient-v9 { background: linear-gradient(120deg, #d299c2 0%, #fef9d7 100%); }
.bg-gradient-v10 { background: linear-gradient(120deg, #fddb92 0%, #d1fdff 100%); }
.bg-gradient-v11 { background: linear-gradient(120deg, #fff1eb 0%, #ace0f9 100%); }
.bg-gradient-v12 { background: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%); }

