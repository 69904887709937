
.overlay {
  position: relative;
  overflow: hidden; // so that overlay won't overflow

  &:before {
    opacity: .3;
    content: '';
    position: absolute;
    background-color: $gray-900;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.overlay-top-down {
  position: relative;
  overflow: hidden;

  &:before {
    opacity: .8;
    content: '';
    position: absolute;
    width: 100%;
    height: 80%;
    left: 0;
    top: 0;
    background: linear-gradient(to bottom, $gray-900 0%, rgba($gray-900, 0) 100%);
  }
}

.overlay-bottom-up {
  position: relative;
  overflow: hidden;

  &:before {
    opacity: .8;
    content: '';
    position: absolute;
    width: 100%;
    height: 80%;
    left: 0;
    bottom: 0;
    background: linear-gradient(to top, $gray-900 0%, rgba($gray-900, 0) 100%);
  }
}

.overlay-light {
  &:before {
    background-color: $white;
  }
}

.overlay-opacity-1 { @include overlay-opacity(.1) };
.overlay-opacity-2 { @include overlay-opacity(.2) };
.overlay-opacity-3 { @include overlay-opacity(.3) };
.overlay-opacity-4 { @include overlay-opacity(.4) };
.overlay-opacity-5 { @include overlay-opacity(.5) };
.overlay-opacity-6 { @include overlay-opacity(.6) };
.overlay-opacity-7 { @include overlay-opacity(.7) };
.overlay-opacity-8 { @include overlay-opacity(.8) };
.overlay-opacity-9 { @include overlay-opacity(.9) };


