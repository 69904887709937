// stylelint-disable declaration-no-important

@each $color, $value in $grays {
  @include bg-variant(".bg-gray-#{$color}", $value);
}
// Example
// .bg-gray-100 {
//   background-color: $gray-100 !important;
// }

.bg-body { 
  background-color: $body-bg !important;
}

.bg-page {
  background-color: $page-bg !important;
}
