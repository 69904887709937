$hover-color: darken($body-color, 15%) !default;


.link-animated-hover {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: $body-color;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: $hover-color;
  }

  &:before, &:after {
    content: '';
    position: absolute;
    transition: transform .25s;
    background: $hover-color;
  }

  &.text-primary {
    &:hover,
    &:focus,
    &:active {
      color: $primary;
    }
    &:before, &:after {
      background: $primary;
    }
  }
}


a {

  &.link-hover-v1 {
    &:after {
      display: block;
      width: 0;
      height: 2px;
      transition: all .25s;
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }
  }

  &.link-hover-v2 {
    &:after {
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 2px;
      transform: scaleX(0);
    }
    &:hover:after {
      transform: scaleX(1);
    }
  }

  &.link-hover-v3 {
    &:after {
      transition: transform .25s cubic-bezier(0.25, 0.8, 0.25, 1);

      left: 0;
      bottom: -1px;
      width: 100%;
      height: 1px;
      transform-origin: right center;
      transform: scaleX(0);
    }
    &:hover {
      &:after {
        transform-origin: left center;
        transform: scaleX(1);
      }
    }
  }

  &.link-hover-v4 {
    padding: 10px;
    &:before, &:after {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: none; // override
      border-style: solid;
      border-color: $hover-color;
    }
    &:before {
      border-width: 2px 0 2px 0;
      transform:  scaleX(0);
    }
    &:after {
      border-width: 0 2px 0 2px;
      transform:  scaleY(0);
    }
    &:hover:before, &:hover:after {
      transform:  scale(1, 1);
    }

    &.text-primary {
      &:before, &:after {
        border-color: $primary;
      }
    }
  }

  &.link-hover-v5 {
    padding: 10px;
    display: inline-block;
    overflow: hidden;
    &:before, &:after {
      left: 0;
      width: 100%;
      height: 2px;
    }
    &:before {
      bottom: 0;
      transform:  translateX(-100%);
    }
    &:after {
      top: 0;
      transform:  translateX(100%);
    }
    &:hover:before, &:hover:after {
      transform:  translateX(0);
    }
  }
}
