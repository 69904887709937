.pricing-table-v1 {
  margin-bottom: 20px;
  padding: 4em 2.4em;
  background-color: #fcfcfc;
  color: $body-color;
  border-radius: $border-radius-lg;
  box-shadow: none;

  header {
    h2 {
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
      font-weight: $font-weight-light;
      line-height: 1;
      color: $primary;
      text-transform: uppercase;
      letter-spacing: 1px;

      span {
        letter-spacing: normal;
        margin-left: .6em;
        font-size: $font-size-sm;
        font-weight: $font-weight-normal;
        color: $body-color;
      }
    }
  }

  .pricing-price {
    margin-bottom: .4em;
    font-size: 40px;
    line-height: 1;
    color: $dark;
    font-weight: $font-weight-light;
    span {
      margin-left: .4em;
      text-transform: uppercase;
      font-size: 22px;
      line-height: 1;
    }
  }

  .pricing-price-detail {
    margin-bottom: 1.2rem;
    font-size: $font-size-sm;
    color: #a1a8b9;
    line-height: 1.25rem;

    &:after {
      content: '';
      height: 0px;
      width: 100px;
      padding-top: 1.2rem;
      border-bottom: solid 3px rgba(0,0,0,.08);
      display: block;
    }
  }

  .pricing-plan-details {
    > ul {
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: .9375rem;
      line-height: 2em;
    }
  }

  > footer {
    margin-top: 50px;
    > a {
      display: block;
      margin: 0;
      font-size: 16px;
    }
  }

  // 
  &.pricing-table-highlight {
    background-color: rgba($info, .08);
  }
}
