$testimonial-v1-bg:  $white;

.testimonial-v1 {
  .testimonial-content {
    position: relative;
    padding: 30px;
    margin: 0 0 30px;
    font-size: $font-size-base; // 14px
    line-height: 1.8;
    border-radius: $border-radius;
    background-color: $testimonial-v1-bg;
    &:after {
      content: "";
      position: absolute;
      bottom: -30px;
      left: 20px;
      border-right: 30px solid transparent;
      border-top: 30px solid;
      border-top-color: $testimonial-v1-bg;
      border-bottom-color: transparent !important;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
    }
  }

  .testimonial-meta {
    margin: 0 0 0 30px;
  }

  .author-infos-holder {
    display: flex;
    align-items: center;
  }

  .author-img {
    margin-right: 15px;

    img {
      max-width: 60px;
      border-radius: 50%;
    }
  }

  .author-desc {
    margin-top: .2em;
    font-size: $font-size-sm;
    opacity: .7;
  }
}


// 
.testimonial-v2 {
  position: relative;
  padding: 3em 1em 3.5em;
  text-align: center;
  border-radius: $border-radius;
  margin-bottom: 34px; // author image height /2

  .testimonial-content {
    font-size: 1rem;
    line-height: 1.5em;
    margin-bottom: .75rem;

    &:before {
      display: block;
      content: '\201C'; // quote
      font-size: 3.5rem;
      line-height: .2;
    }
  }

  cite {
    opacity: .7;
  }

  .author-img {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    width: 68px;
    height: 68px;
    margin-bottom: -34px;
    padding: 4px;
    background-color: $page-bg; // same with container bg
    border-radius: 50%;

    img {
      max-width: 100%;
      border-radius: 50%;
    }
  }
}


// 
.testimonial-v3 {
  text-align: center;

  .avatar {
    max-width: 100px;
    margin-bottom: 1rem;
    border-radius: 50%;
    max-width: 80px;
  }

  blockquote {
    &:before,
    &:after {
      content: none;
    }
    margin: 0 0 .2em;
    font-size: $font-size-lg;
    opacity: .9;
    border-left: 0;
  }

  .citation {
    font-size: $font-size-base;
    opacity: .54;
    margin-bottom: 0;
  }

}